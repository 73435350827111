!<template>
<div :class="[{ 'active': showPopup }, 'popup-overlay']">
  <div class="popup-inr message-box notification">
    <div class="close-popup" @click="onClose"></div>
    <h2>{{ $t('Common.Notification')}}</h2>
    <div class="outer-wrap">
        <div class="form-box">
            <div class="fs-txt">
                <p>{{ $t('PopUp.LoginRequired')}}</p>
            </div>
            <div class="fs-group fs-buts small-size">
                <router-link :to="$t('Route.Login')">
                    <a type="button" class="fs-but next-mask-red" title="ĐỒNG Ý">
                        <span>{{ $t('Common.AGREE')}}</span>
                    </a>
                </router-link>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
export default {
  props: ["showPopup"],
  data() {
    return {
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
  z-index: 50;
  text-align: center;
  display: none;
  overflow: hidden;
  overflow-y: auto;

  .close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    background-size: 60% auto;
    cursor: pointer;
    z-index: 3;
    outline: none;
    background: url('../assets/images/icons/close-popup.svg') no-repeat 50%;
    opacity: 0.5;
    height: 15px;
    width: 15px;
     &:hover {
      filter: invert(20%) sepia(93%) saturate(1352%) hue-rotate(316deg) brightness(119%) contrast(119%);
    }
  }

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-inr.message-box {
    max-width: 57rem;
  }

  .message-box.notification {
    max-width: 57rem;
    margin: 0 auto 6rem;
    padding: 4.6rem 5rem;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 5%);
    border-radius: 1rem;
    text-align: center;
  }

  .popup-inr {
    width: 90%;
    height: auto;
    max-width: 827px;
    max-height: inherit;
    background-color: #fff;
    text-align: left;
  }

  p {
    line-height: 1.4;
  }

  .popup-inr, .popup-overlay:after, .popup-overlay:before {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .pack-note-title {
    margin: -1rem 0 0;
    font-size: 2.4rem;
    color: #000;
    font-weight: 400;
  }

  @media screen and (min-width: 1100px) {

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .message-box h1, .popup-inr.message-box h2 {
    color: #3a3a3a;
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 3.6rem;
  }

  .fs-buts {
    display: block;
    font-size: 0;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  .fs-but {
    display: inline-block;
    padding: 1.2rem 5.55rem;
    background-color: #ff2424;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    border-radius: 10px 4px 10px 4px;
    border: 2px solid rgba(255,36,36,0);
    cursor: pointer;
    text-align: center;
  }
  
  .fs-but, .fs-buts {
    position: relative;
  }

  .fs-group {
    margin-bottom: 1.7rem;
  }

  .fs-group .fs-but {
    display: block;
    width: 100%;
    padding: 0 5.55rem;
    height: 6rem;
    line-height: 6rem;
  }

  @media screen and (max-width: 1260px) {
    .fs-but {
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 1200px) {
    .fs-but {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: 1101px) {
    .fs-but:hover {
      background-color: #fff;
      border: 2px solid #ff2424;
      color: #ff2424;
    }
  }

  @media screen and (max-width: 1100px) {
    .pack-note-title {
      font-size: 3.623vw;
    }

    .popup-inr.message-box {
      max-width: inherit;
      margin: 5vw 0;
    }

    .message-box, .message-box.notification {
      padding: 8vw 5.2vw;
    }

    .popup-inr.message-box h1, .popup-inr.message-box h2 {
      font-size: 5.3vw;
    }

    .message-box h1, .popup-inr.message-box h2 {
      font-size: 5.7vw;
      margin-bottom: 4.8vw;
    }

    .fs-group {
      margin-bottom: 4.428vw;
    }

    .fs-but {
      padding: 3.5vw 8vw;
      font-size: 4.1868vw;
      border-radius: 1.6vw 0.5vw 1.6vw 0.5vw;
    }

    .fs-group .fs-but {
      padding: 3.5vw 8vw;
      font-size: 3.623vw;
      height: auto;
      line-height: normal;
    }
  }
}
</style>
