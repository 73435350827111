<template>
  <div id="support">
    <div class="fs-container">
      <div
          class="fs-section fs-support"
          :class="'/' + Slug == $t('Route.SimRegister') ||
            '/' + Slug == $t('Route.ESimExchange') ||
            '/' + Slug == $t('Route.SimExchange')
            ? 'pt-4 fs-sp-pt-3' : ''"
      >
        <div
            v-if="'/' + Slug != $t('Route.SimRegister') &&
            '/' + Slug != $t('Route.ESimExchange') &&
            '/' + Slug != $t('Route.SimExchange')"
            class="fs-inr support-top fs-sp-mt-0 fs-sp-pt-0"
            :class="
            '/' + Slug == $t('Route.ContactUs')
              ? 'fs-pc'
              : ''
          "
        >
          <div class="message-box follow-register">
            <div class="outer-wrap">
              <h1 v-html="$t('Common.HelloCus')"></h1>
              <div class="form-box">
                <form v-on:submit.prevent="">
                  <div class="row fs-group row-gap-2">
                    <div class="col-md-4">
                      <Select2
                          :settings="{ minimumResultsForSearch: -1 }"
                          class="select2-custom"
                          :placeholder="$t('Common.Choose a topic')"
                          v-model="topicSelect"
                          :options="topicData"
                          @change="onSelectTopic()"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="group-question">
                        <b-form-input
                            v-model="SearchString"
                            :placeholder="$t('Common.EnterQuestion')"
                        />
                        <button class="btn-question-search" @click="onSearch()">
                          <img src="../assets/images/icons/search-icon.svg" alt=""/>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-content">
        <div class="nav-content fs-pc">
          <div class="nav-inr" v-if="!isLoading">
            <div class="small-title">{{ $t("Common.Category") }}</div>
            <ul>
              <li v-for="i in cat" :key="i.Slug" :class="i.class">
                <router-link :to="$t('Route.Support') + i.Slug" :title="i.Name"
                >{{ i.Name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="main-content" v-if="!isLoading">
          <div
              id="faqComp"
              class="support-block"
              v-if="(Slug == undefined || '/' + Slug == $t('Route.Faq')) && !isEmptyTopic"
          >
            <div
                class="question-relate no-bg"
                :style="!isShowCategoryName ? 'margin-bottom: 0; margin-top: 0' : ''"
                v-for="i in topicData.filter(
                (j) =>
                  !topicSelect || topicSelect == '0' || 
                  j.id > 0 &&
                  (j.id == topicSelect) &&
                  RelatedFaq.filter((o) => o.FaqCategoryId == j.id && findStr(o)).length >
                    0
              )"
                :key="i.Id"
            >
              <h2 class="mb-1" v-if="isShowCategoryName">{{ i.text }}</h2>
              <div class="fs-faq-items">
                <template
                    v-for="faq of RelatedFaq.filter((j) => j.FaqCategoryId == i.id)"
                >
                  <div v-if="findStr(faq)" class="fs-faq-item" :key="faq.Id">
                    <p
                        class="h2 mt-2 mb-2"
                        :title="faq.Question"
                        @click="onClickFap(faq)"
                    >
                      {{ faq.Question }}
                    </p>
                    <div class="support-block mb-2" v-if="faq.Id == currentFaq">
                      <div class="faq-content">
                        <div style="text-align: justify;" v-html="faq.Answer"/>
                      </div>
                      <span class="title-like">{{ $t("SupportPage.FaqTitleLike") }}</span>
                      <div class="social-like">
                        <ul>
                          <li
                              class="faq-like"
                              @click="reactLike(faq.Id)"
                              :class="faq.currentUserReaction == 'Liked' ? 'active' : ''"
                          >
                            <div class="fs-pic">
                              <img
                                  src="@/assets/images/ho-tro/icon-like.png"
                                  :alt="$t('SupportPage.FaqYesText')"
                              />
                            </div>
                            <span>{{ $t("SupportPage.FaqYesText") }}</span>
                          </li>
                          <li
                              class="faq-unline"
                              @click="reactDislike(faq.Id)"
                              :class="faq.currentUserReaction == 'DisLiked' ? 'active' : ''"
                          >
                            <div class="fs-pic">
                              <img
                                  src="@/assets/images/ho-tro/icon-unlike.png"
                                  :alt="$t('SupportPage.FaqNoText')"
                              />
                            </div>
                            <span>{{ $t("SupportPage.FaqNoText") }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
              id="faqComp"
              class="support-block"
              v-if="(Slug == undefined || '/' + Slug == $t('Route.Faq')) && isEmptyTopic"
          >
            <div class="question-relate no-bg">
              <h2 class="mb-1">{{ $t("Common.topic-none-message") }}</h2>
            </div>
          </div>
          <template v-if="'/' + Slug == $t('Route.ContactUs')">
            <div class="support-title fs-sp-pt-3">
              <h2>{{ $t("SupportPage.ContactUsText") }}</h2>
              <ul class="breadcrumb">
                <li>
                  <router-link
                      :to="$t('Route.Support')"
                      :title="$t('SupportPage.SuportPageHref')"
                  >{{ $t("SupportPage.SuportPageHref") }}
                  </router-link
                  >
                </li>
                <li>
                  <span>{{ $t("SupportPage.ContactUsText") }}</span>
                </li>
              </ul>
            </div>

            <div class="support-block">
              <div class="contact-items">
                <a
                    href="https://m.me/itel.fan"
                    class="contact-item"
                    title="Message"
                    target="_blank"
                >
                  <span class="contact-pic">
                    <img
                        loading="lazy"
                        src="@/assets/images/contact-mesenger.png"
                        alt="Messenger"
                    />
                  </span>
                  <span class="contact-txt">Messenger</span>
                </a>
                <a
                    :href="
                    'tel:' + ConfigData.find((i) => i.DataKey == 'PhoneWebsite').Value
                  "
                    class="contact-item"
                >
                  <span class="contact-pic">
                    <img
                        loading="lazy"
                        src="@/assets/images/contact-phone.png"
                        alt="Hotline ITEL"
                    />
                  </span>
                  <span class="contact-txt">{{
                      $t("SupportPage.ContactUsHotline")
                    }}</span>
                </a>
                <a href="https://zalo.me/3281327437324952111" class="contact-item">
                  <span class="contact-pic">
                    <img
                        loading="lazy"
                        src="@/assets/images/contact-zalo.png"
                        alt="Zalo"
                        class="contact-icon"
                    />
                  </span>
                  <span class="contact-txt">Zalo</span>
                </a>
              </div>
            </div>
          </template>
          <template v-if="'/' + Slug == $t('Route.SimRegister')">
            <div class="support-title">
              <h2>{{ $t("SupportPage.RegisterTTTBText") }}</h2>
              <ul class="breadcrumb">
                <li>
                  <router-link
                      :to="$t('Route.Support')"
                      :title="$t('SupportPage.SuportPageHref')"
                  >{{ $t("SupportPage.SuportPageHref") }}
                  </router-link
                  >
                </li>
                <li>
                  <span>{{ $t("SupportPage.RegisterTTTBBreadcrumbText") }}</span>
                </li>
              </ul>
            </div>

            <div class="support-block">
              <validation-observer ref="modalDKTT">
                <div class="form-box-new">
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.DKTTBCaption") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|msisdn"
                        >
                          <b-form-group>
                            <b-form-input
                                @keypress="preventEnterSpecialCharacter($event, true)"
                                :placeholder="$t('SupportPage.CCQPhoneRegister')"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.Phone"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.CCQCaptionContact") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|num">
                          <b-form-group>
                            <b-form-input
                                @keypress="preventEnterSpecialCharacter($event, true)"
                                :placeholder="$t('SupportPage.CCQContactPhoneRegister')"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.ContactPhone"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="fs-group has-line">
                    <div class="col">
                      <div class="fs-caption">
                        {{ $t("SupportPage.CCQImgSimSerialCapption") }}
                      </div>
                      <div class="box-select-image">
                        <div class="fs-box box-card" v-if="!isCapture">
                          <label for="avaSim">
                            <img
                                class="pic-user"
                                v-if="
                                currentItem.simImg == undefined ||
                                currentItem.simImg == null
                              "
                                @click="showCapture('simImg')"
                                src="@/assets/images/ho-tro/phoi-sim-1.svg"
                                alt="Avatar user"
                            />
                            <img
                                class="pic-user"
                                v-else
                                @click="showCapture('simImg')"
                                :src="currentItem.simImg"
                                alt="Avatar user"
                            />
                          </label>
                          <span
                              class="avatar-txt"
                              v-if="
                              currentItem.simImg == undefined ||
                              currentItem.simImg == null
                            "
                          >{{ $t("SupportPage.CCQImgSimSerialInput") }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Gender") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|gender">
                          <b-form-group>
                            <Select2
                                class="select2-custom"
                                :settings="{ minimumResultsForSearch: -1 }"
                                :placeholder="$t('Common.Choosen gender')"
                                v-model="currentItem.gender"
                                :options="gender"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.GenderError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="accuracy-checks fs-group">
                      <div class="fs-caption">{{ $t("Common.Verify") }}</div>
                      <ul class="fs-checkbox">
                        <li>
                          <input
                              id="CMTSimRegister"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios4"
                              value="CMT"
                          />
                          <label for="CMTSimRegister">{{ $t("SupportPage.CMND") }}</label>
                        </li>
                        <li>
                          <input
                              id="CCSimRegister"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios4"
                              value="CC"
                          />
                          <label for="CCSimRegister">{{ $t("SupportPage.CC") }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accuracy-note">
                    <p>
                      {{ $t("SupportPage.AccuracyNote3") }}
                      <!-- <span class="has-small-tick">
                        <img
                          src="@/assets/images/services/img-tick.png"
                          :alt="$t('SupportPage.PickNumberBuySim')"
                        /> </span
                      >{{ $t("SupportPage.AccuracyNote2") }} -->
                    </p>
                  </div>
                  <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label :class="currentItem.frontImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.frontImg == undefined ||
                            currentItem.frontImg == null ||
                            isUploadFront
                          "
                            @click="showCapture('frontImg')"
                            src="@/assets/images/services/img-identity-1.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('frontImg')"
                            :src="currentItem.frontImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null ||
                          isUploadFront
                        "
                          v-html="$t('SupportPage.TakeFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label :class="currentItem.backImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null ||
                            isUploadBack
                          "
                            @click="showCapture('backImg')"
                            src="@/assets/images/services/img-identity-2.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('backImg')"
                            :src="currentItem.backImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.backImg == undefined ||
                          currentItem.backImg == null ||
                          isUploadBack
                        "
                          v-html="$t('SupportPage.TakeBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label :class="currentItem.faceImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null ||
                            isUploadFace
                          "
                            @click="showCapture('faceImg')"
                            src="@/assets/images/services/img-avatar.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('faceImg')"
                            :src="currentItem.faceImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.faceImg == undefined ||
                          currentItem.faceImg == null ||
                          isUploadFace
                        "
                          v-html="$t('SupportPage.Take user photo')"
                      >
                      </span>
                    </div>
                  </div>
                  <!-- <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label
                        :class="currentItem.frontImgValid"
                        for="frontImgInput"
                        @click="showUpload('frontImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.frontImg == undefined ||
                            currentItem.frontImg == null ||
                            !isUploadFront
                          "
                          src="@/assets/images/services/img-identity-1.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.frontImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="frontImgInput"
                          name="frontImgInput"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 1)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null ||
                          !isUploadFront
                        "
                        v-html="$t('SupportPage.UploadFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label
                        :class="currentItem.backImgValid"
                        for="backImgInput"
                        @click="showUpload('backImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null ||
                            !isUploadBack
                          "
                          src="@/assets/images/services/img-identity-2.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.backImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="backImgInput"
                          name="backImgInput"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 2)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.backImg == undefined ||
                          currentItem.backImg == null ||
                          !isUploadBack
                        "
                        v-html="$t('SupportPage.UploadBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label
                        :class="currentItem.faceImgValid"
                        for="faceImg"
                        @click="showUpload('faceImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null ||
                            !isUploadFace
                          "
                          src="@/assets/images/services/img-avatar.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.faceImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="faceImg"
                          name="faceImg"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 3)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.faceImg == undefined ||
                          currentItem.faceImg == null ||
                          !isUploadFace
                        "
                        v-html="$t('SupportPage.Upload user photo')"
                      >
                      </span>
                    </div>
                  </div> -->
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.FullName") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                v-if="!isCapture"
                                placeholder="NGUYEN VAN A"
                                class=""
                                :state="errors.length > 0 ? false : null"
                                disabled
                                v-model="currentItem.name"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.IdNumberWithoutPP") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.YourIdNumber')"
                                class="icon-card"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.id"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Dob") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Dob')"
                                class="icon-date"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.birth_day"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.IssueDate") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssueDate')"
                                disabled
                                class="icon-date"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_date"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("Common.Address") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Address')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.recent_location"
                                @keypress="preventEnterSpecialCharacter($event)"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.AddressError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("SupportPage.IssuePlace") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssuePlace')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_place"
                                @keypress="preventEnterSpecialCharacter($event)"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.IssuePlaceError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <button
                      @click="dktttb()"
                      type="button"
                      class="fs-but next-mask-red"
                      title="GỬI YÊU CẦU ĐK TTTB"
                  >
                    GỬI YÊU CẦU ĐK TTTB
                  </button>
                </div>
              </validation-observer>
            </div>

            <div class="support-block">
              <div class="question-relate">
                <h2>{{ $t("SupportPage.RelatedQuestion") }}</h2>
                <div class="fs-faq-items">
                  <div
                      class="question-relate no-bg"
                      v-for="i in topicData.filter((j) => j.id == 8)"
                      :key="i.Id"
                  >
                    <div class="fs-faq-items">
                      <template
                          v-for="faq of RelatedFaq.filter((j) => j.FaqCategoryId == i.id)"
                      >
                        <div v-if="findStr(faq)" class="fs-faq-item" :key="faq.Id">
                          <p
                              class="h2 mt-2 mb-2"
                              :title="faq.Question"
                              @click="onClickFap(faq)"
                          >
                            {{ faq.Question }}
                          </p>
                          <div class="support-block mb-2" v-if="faq.Id == currentFaq">
                            <div class="faq-content">
                              <div style="text-align: justify;" v-html="faq.Answer"/>
                            </div>
                            <span class="title-like">{{
                                $t("SupportPage.FaqTitleLike")
                              }}</span>
                            <div class="social-like">
                              <ul>
                                <li
                                    class="faq-like"
                                    @click="reactLike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'Liked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-like.png"
                                        :alt="$t('SupportPage.FaqYesText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqYesText") }}</span>
                                </li>
                                <li
                                    class="faq-unline"
                                    @click="reactDislike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'DisLiked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-unlike.png"
                                        :alt="$t('SupportPage.FaqNoText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqNoText") }}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template
              v-if="
              '/' + Slug == $t('Route.ESimExchange') ||
              '/' + Slug == $t('Route.SimExchange')
            "
          >
            <div class="support-title" v-if="!isInIframe">
              <h2 v-if="'/' + Slug == $t('Route.ESimExchange')">Đổi/Cấp lại eSIM</h2>
              <h2 v-else>Đổi/Cấp lại SIM</h2>
              <ul class="breadcrumb">
                <li>
                  <router-link
                      :to="$t('Route.Support')"
                      :title="$t('SupportPage.SuportPageHref')"
                  >{{ $t("SupportPage.SuportPageHref") }}
                  </router-link
                  >
                </li>
                <li>
                  <span v-if="'/' + Slug == $t('Route.ESimExchange')"
                  >Đổi/Cấp lại eSIM</span
                  >
                  <span v-else>Đổi/Cấp lại SIM</span>
                </li>
              </ul>
            </div>
            <div class="support-title" v-else>
              <h2 v-if="'/' + Slug == $t('Route.SimExchange')">Thông tin đổi cấp lại SIM</h2>
              <h2 v-else>Thông tin đổi cấp lại eSIM</h2>
            </div>

            <div class="support-block" v-if="!isInIframe">
              <validation-observer ref="modalDKTT">
                <div class="form-box-new">
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{
                          "/" + Slug == $t("Route.SimExchange")
                              ? $t("SupportPage.DCLSCaption")
                              : $t("SupportPage.DCLSCaptionESim")
                        }}
                      </div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|msisdn"
                        >
                          <b-form-group>
                            <b-form-input
                                :placeholder="
                                '/' + Slug == $t('Route.SimExchange')
                                  ? $t('SupportPage.DCLSCaption')
                                  : $t('SupportPage.DCLSCaptionESim')
                              "
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.Phone"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group" v-if="'/' + Slug == $t('Route.ESimExchange')">
                      <div class="fs-caption">
                        Dòng máy hỗ trợ  <span style="color: red">*</span>
                      </div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|msisdn"
                        >
                          <b-form-group >
                              <div class="selectedDevice d-flex align-items-center" @click="showDeviceSearch()"
                                 ref="selectSupportDevice" :style="selectedDevice.Code===-1?'color: #999;':''"
                              >{{selectedDevice.Name}}</div>
                            <img class="position-absolute" style="top: 37%; right: 18%;rotate: 90deg"
                                 src="@/assets/images/icons/arrow-right.svg" @click="showDeviceSearch()">
                            <ul class="ul-device-search" v-if="isShowDeviceSearch" >
                              <b-form-input ref="txtSearch" v-on:keyup.enter="searchDevice()" @update="searchDevice()"
                                            v-model="inputSearchDevice" style="border-radius: 0px !important; position: sticky"
                                            id="searchright"
                                            />
                              <ul class="ul-device-search-result">
                                <li v-for="(p, stt) in searchDeviceResult"
                                    class="search-device-result" :key="stt" :title="p.Name"
                                    :style="p.Code===0?'color:red':''"
                                    @click="selectDevice(p)">
                                  {{ p.Name }}
                                </li>
                              </ul>
                            </ul>
                            <small class="fs-error-txt" style="display: none" ref="selectDeviceError">
                              Vui lòng chọn dòng máy hỗ trợ
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="fs-caption">
                    Nhập 3 số điện thoại gọi đi/nhắn tin trong 3 tháng gần đây từ số iTel của bạn
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|num|min10">
                          <b-form-group>
                            <b-form-input
                                :class="
                                validateRecentphone(currentItem.RecentPhone1)
                                  ? ''
                                  : 'warning-input'
                              "
                                placeholder="1231515111"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.RecentPhone1"
                                @keydown="isNumber($event, currentItem.RecentPhone1)"
                                @change="checkRecentPhoneMatch()"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                            <small
                                class="fs-error-txt"
                                v-if="
                                errors.length == 0 &&
                                !validateRecentphone(currentItem.RecentPhone1)
                              "
                            >{{
                                $t("SupportPage.CCQPhoneRegisterDuplicateError")
                              }}</small
                            >
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|num|min10">
                          <b-form-group>
                            <b-form-input
                                :class="
                                validateRecentphone(currentItem.RecentPhone2)
                                  ? ''
                                  : 'warning-input'
                              "
                                placeholder="1231515112"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.RecentPhone2"
                                @keydown="isNumber($event, currentItem.RecentPhone2)"
                                @change="checkRecentPhoneMatch()"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                            <small
                                class="fs-error-txt"
                                v-if="
                                errors.length == 0 &&
                                !validateRecentphone(currentItem.RecentPhone2)
                              "
                            >{{
                                $t("SupportPage.CCQPhoneRegisterDuplicateError")
                              }}</small
                            >
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|num|min10">
                          <b-form-group>
                            <b-form-input
                                :class="
                                validateRecentphone(currentItem.RecentPhone3)
                                  ? ''
                                  : 'warning-input'
                              "
                                placeholder="1231515113"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.RecentPhone3"
                                @keydown="isNumber($event, currentItem.RecentPhone3)"
                                @change="checkRecentPhoneMatch()"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                            <small
                                class="fs-error-txt"
                                v-if="
                                errors.length == 0 &&
                                !validateRecentphone(currentItem.RecentPhone3)
                              "
                            >{{
                                $t("SupportPage.CCQPhoneRegisterDuplicateError")
                              }}</small
                            >
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Gender") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|gender">
                          <b-form-group>
                            <Select2
                                :settings="{ minimumResultsForSearch: -1 }"
                                :placeholder="$t('Common.Choosen gender')"
                                class="select2-custom"
                                v-model="currentItem.gender"
                                :options="gender"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.GenderError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="accuracy-checks fs-group">
                      <div class="fs-caption">{{ $t("Common.Verify") }}</div>
                      <ul class="fs-checkbox">
                        <li>
                          <input
                              id="CMTSim"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios1"
                              value="CMT"
                          />
                          <label for="CMTSim">{{ $t("SupportPage.CMND") }}</label>
                        </li>
                        <li>
                          <input
                              id="CCSim"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios1"
                              value="CC"
                          />
                          <label for="CCSim">{{ $t("SupportPage.CC") }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accuracy-note">
                    <p>
                      {{ $t("SupportPage.AccuracyNote3") }}
                      <!-- {{ $t("SupportPage.AccuracyNote1") }}
                      <span class="has-small-tick">
                        <img
                          src="@/assets/images/services/img-tick.png"
                          :alt="$t('SupportPage.PickNumberBuySim')"
                        /> </span
                      >{{ $t("SupportPage.AccuracyNote2") }} -->
                    </p>
                  </div>
                  <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label :class="currentItem.frontImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.frontImg == undefined ||
                            currentItem.frontImg == null ||
                            isUploadFront
                          "
                            @click="showCapture('frontImg')"
                            src="@/assets/images/services/img-identity-1.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('frontImg')"
                            :src="currentItem.frontImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null ||
                          isUploadFront
                        "
                          v-html="$t('SupportPage.TakeFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label :class="currentItem.backImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null ||
                            isUploadBack
                          "
                            @click="showCapture('backImg')"
                            src="@/assets/images/services/img-identity-2.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('backImg')"
                            :src="currentItem.backImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.backImg == undefined ||
                          currentItem.backImg == null ||
                          isUploadBack
                        "
                          v-html="$t('SupportPage.TakeBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label :class="currentItem.faceImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null ||
                            isUploadFace
                          "
                            @click="showCapture('faceImg')"
                            src="@/assets/images/services/img-avatar.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('faceImg')"
                            :src="currentItem.faceImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.faceImg == undefined ||
                          currentItem.faceImg == null ||
                          isUploadFace
                        "
                          v-html="$t('SupportPage.Take user photo')"
                      >
                      </span>
                    </div>
                  </div>
                  <!-- <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label
                        :class="currentItem.frontImgValid"
                        for="frontImgInput"
                        @click="showUpload('frontImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.frontImg == undefined ||
                            currentItem.frontImg == null ||
                            !isUploadFront
                          "
                          src="@/assets/images/services/img-identity-1.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.frontImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="frontImgInput"
                          name="frontImgInput"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 1)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null ||
                          !isUploadFront
                        "
                        v-html="$t('SupportPage.UploadFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label
                        :class="currentItem.backImgValid"
                        for="backImgInput"
                        @click="showUpload('backImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null ||
                            !isUploadBack
                          "
                          src="@/assets/images/services/img-identity-2.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.backImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="backImgInput"
                          name="backImgInput"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 2)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.backImg == undefined ||
                          currentItem.backImg == null ||
                          !isUploadBack
                        "
                        v-html="$t('SupportPage.UploadBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label
                        :class="currentItem.faceImgValid"
                        for="faceImg"
                        @click="showUpload('faceImg')"
                      >
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null ||
                            !isUploadFace
                          "
                          src="@/assets/images/services/img-avatar.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          :src="currentItem.faceImg"
                          alt="Avatar user"
                        />
                        <input
                          type="file"
                          id="faceImg"
                          name="faceImg"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          @change="uploadImage($event, 3)"
                        />
                      </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.faceImg == undefined ||
                          currentItem.faceImg == null ||
                          !isUploadFace
                        "
                        v-html="$t('SupportPage.Upload user photo')"
                      >
                      </span>
                    </div>
                  </div> -->
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.FullName") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                v-if="!isCapture"
                                placeholder="NGUYEN VAN A"
                                class=""
                                :state="errors.length > 0 ? false : null"
                                disabled
                                v-model="currentItem.name"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.IdNumberWithoutPP") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                                size="sm"
                                class="confirm-input disabled"
                                :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                  :placeholder="$t('SupportPage.YourIdNumber')"
                                  class="icon-card"
                                  disabled
                                  :state="errors.length > 0 ? false : null"
                                  v-model="currentItem.id"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.idNumber ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.idNumber = !confirmData.idNumber"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Dob") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                                size="sm"
                                class="confirm-input disabled"
                                :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                  :placeholder="$t('Common.Dob')"
                                  class="icon-date"
                                  disabled
                                  :state="errors.length > 0 ? false : null"
                                  v-model="currentItem.birth_day"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.dateOfBirth ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="
                                    confirmData.dateOfBirth = !confirmData.dateOfBirth
                                  "
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.IssueDate") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                                size="sm"
                                class="confirm-input disabled"
                                :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                  :placeholder="$t('SupportPage.IssueDate')"
                                  disabled
                                  class="icon-date"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="currentItem.issue_date"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.issueDate ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.issueDate = !confirmData.issueDate"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("Common.Address") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-input-group
                                size="sm"
                                class="confirm-input"
                                :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                  :placeholder="$t('Common.Address')"
                                  class="icon-addr"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="currentItem.recent_location"
                                  @keypress="preventEnterSpecialCharacter($event)"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.address ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.address = !confirmData.address"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.AddressError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("SupportPage.IssuePlace") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-input-group
                                size="sm"
                                class="confirm-input"
                                :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                  :placeholder="$t('SupportPage.IssuePlace')"
                                  class="icon-addr"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="currentItem.issue_place"
                                  @keypress="preventEnterSpecialCharacter($event)"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.issuePlace ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="
                                    confirmData.issuePlace = !confirmData.issuePlace
                                  "
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.IssuePlaceError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <button
                      v-if="'/' + Slug == $t('Route.ESimExchange')"
                      @click="doi_sim(1)"
                      type="button"
                      class="fs-but next-mask-red"
                      title="Gửi yêu cầu Đổi/cấp lại eSIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại eSIM
                  </button>
                  <button
                      v-else
                      @click="doi_sim(0)"
                      type="button"
                      class="fs-but next-mask-red"
                      title="Gửi yêu cầu Đổi/cấp lại SIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại SIM
                  </button>
                </div>
              </validation-observer>
            </div>
            <div class="support-block" v-else>
              <validation-observer ref="modalDKTTIframe">
                <div class="form-box-new">
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{  $t("SupportPage.DCLSCaptionIframe")}} <span style="color: red;"> *</span>
                      </div>
                      <div class="col p-0">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|msisdn"
                        >
                          <b-form-group>
                            <b-form-input
                              :placeholder="$t('SupportPage.DCLSCaptionIframe')"
                              maxlength="11"
                              pattern="[0-9]{10,11}"
                              :state="errors.length > 0  || (isCheckValidateIframe && !currentItem.Phone) ? false : null"
                              v-model="currentItem.Phone"
                              type="number"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0 || (isCheckValidateIframe && !currentItem.Phone)">{{
                              $t("SupportPage.CCQPhoneRegisterError")
                            }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group" v-if="'/' + Slug == $t('Route.ESimExchange')">
                      <div class="fs-caption">
                        Dòng máy hỗ trợ  <span style="color: red">*</span>
                      </div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules=""
                        >
                          <b-form-group >
                              <div class="selectedDevice d-flex align-items-center" @click="showDeviceSearch()"
                                 ref="selectSupportDevice" :style="selectedDevice.Code===-1?'color: #999;':''"
                              >{{selectedDevice.Name}}</div>
                            <img class="position-absolute" style="top: 37%; right: 18%;rotate: 90deg"
                                 src="@/assets/images/icons/arrow-right.svg" @click="showDeviceSearch()">
                            <ul class="ul-device-search" v-if="isShowDeviceSearch" >
                              <b-form-input ref="txtSearch" v-on:keyup.enter="searchDevice()" @update="searchDevice()"
                                            v-model="inputSearchDevice" style="border-radius: 0px !important; position: sticky"
                                            id="searchright"
                                            />
                              <ul class="ul-device-search-result">
                                <li v-for="(p, stt) in searchDeviceResult"
                                    class="search-device-result" :key="stt" :title="p.Name"
                                    :style="p.Code===0?'color:red':''"
                                    @click="selectDevice(p)">
                                  {{ p.Name }}
                                </li>
                              </ul>
                            </ul>
                            <small class="fs-error-txt" style="display: none" ref="selectDeviceError">
                              Vui lòng chọn dòng máy hỗ trợ
                            </small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.Subscriber type") }} <span style="color: red;"> *</span></div>
                      <div class="col p-0">
                        <b-form-group>
                            <Select2
                              :settings="{ minimumResultsForSearch: -1 }"
                              :placeholder="$t('SupportPage.Subscriber type')"
                              class="select2-custom"
                              v-model="currentItem.SubscriptionType"
                              :options="subscriberTypeOptions"
                              :class="(isCheckValidateIframe && !currentItem.SubscriptionType)? 'is-invalid' : null"
                            />
                            <small class="fs-error-txt" v-if="isCheckValidateIframe && !currentItem.SubscriptionType">Vui lòng chọn loại thuê bao</small>
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2" v-if="currentItem.SubscriptionType == 2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.Reproduction of the pledge") }} <span style="color: red;"> *</span></div>
                      <div class="col p-0">
                        <b-form-group>
                          <label ref="inputPledgeKey" :for="inputPledgeKey" style="cursor: pointer;" class="label-pledge-container">
                            <input
                              type="file"
                              hidden
                              :name="inputPledgeKey"
                              :id="inputPledgeKey"
                              accept="image/png, image/jpeg"
                              @change="onLoadPledge"
                            />
                            <img width="100" height="100" alt="img" :src="pledgeImgSrc ? pledgeImgSrc : ImgPledgeDemo"/>
                            <button class="btn-preview-image-container" v-if="pledgeImgSrc">
                              <img src="@/assets/images/preview.png" alt=""  @click="onPreviewPledgeImage()"/>
                              <img src="@/assets/images/edit.png" alt="" @click="onEditPledgeImage()"/>
                            </button>
                          </label>
                        </b-form-group>
                        <small class="fs-error-txt" v-if="isCheckValidateIframe && !currentItem.CommitmentImage">Vui lòng chọn bản cam kết</small>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="fs-caption mb-3">
                    Nhập ít nhất 1 trong 3 điều kiện sau
                  </div>
                  <div class="fs-caption" style="font-weight: 400;">
                    1. Nhập 3 số điện thoại gọi đi/nhắn tin trong 3 tháng gần đây từ số iTel của bạn
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="col p-0">
                        <b-form-group>
                            <b-form-input
                              :class="
                                validateRecentphone(currentItem.RecentPhone1)
                                  ? ''
                                  : 'warning-input'
                              "
                              placeholder="1231515111"
                              maxlength="11"
                              pattern="[0-9]{10,11}"
                              v-model="currentItem.RecentPhone1"
                              @keydown="isNumber($event, currentItem.RecentPhone1)"
                              @change="checkRecentPhoneMatch()"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="col p-0">
                        <b-form-group>
                            <b-form-input
                              :class="
                                validateRecentphone(currentItem.RecentPhone2)
                                  ? ''
                                  : 'warning-input'
                              "
                              placeholder="1231515112"
                              maxlength="11"
                              pattern="[0-9]{10,11}"
                              v-model="currentItem.RecentPhone2"
                              @keydown="isNumber($event, currentItem.RecentPhone2)"
                              @change="checkRecentPhoneMatch()"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="col p-0">
                        <b-form-group>
                            <b-form-input
                              :class="
                                validateRecentphone(currentItem.RecentPhone3)
                                  ? ''
                                  : 'warning-input'
                              "
                              placeholder="1231515113"
                              maxlength="11"
                              pattern="[0-9]{10,11}"
                              v-model="currentItem.RecentPhone3"
                              @keydown="isNumber($event, currentItem.RecentPhone3)"
                              @change="checkRecentPhoneMatch()"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption" style="font-weight: 400;">
                      2. Nhập 11 số serial cũ (nếu có):
                      </div>
                      <div class="col p-0">
                        <b-form-group>
                            <b-form-input
                              placeholder="Nhập 11 số serial cũ (nếu có)"
                              maxlength="11"
                              pattern="[0-9]{10,11}"
                              v-model="currentItem.RecentSimSerial"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption" style="font-weight: 400;">
                      3. Mệnh giá thẻ nạp:
                      </div>
                      <div class="col p-0">
                        <b-form-group>
                            <Select2
                              :settings="{ minimumResultsForSearch: -1 }"
                              placeholder="50.000đ"
                              class="select2-custom"
                              v-model="currentItem.LastRecharge"
                              :options="cardsOptions"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption" style="font-weight: 400;">
                        Ghi chú
                      </div>
                      <div class="col p-0">
                        <b-form-group>
                            <b-form-textarea
                            v-model="currentItem.note"
                              placeholder="Nhập ghi chú"
                            />
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="accuracy-checks fs-group">
                      <div class="fs-caption">Loại giấy tờ:</div>
                      <ul class="fs-checkbox">
                        <li>
                          <input
                            id="CMTSim"
                            type="radio"
                            v-model="currentItem.DocType"
                            class="radio-vetify"
                            name="some-radios1"
                            value="CMT"
                          />
                          <label for="CMTSim">{{ $t("SupportPage.CMND") }}</label>
                        </li>
                        <li>
                          <input
                            id="CCSim"
                            type="radio"
                            v-model="currentItem.DocType"
                            class="radio-vetify"
                            name="some-radios1"
                            value="CC"
                          />
                          <label for="CCSim">{{ $t("SupportPage.CC") }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accuracy-note">
                    <p>
                      {{ $t("SupportPage.AccuracyNote3") }}
                      <!-- {{ $t("SupportPage.AccuracyNote1") }}
                      <span class="has-small-tick">
                        <img
                          src="@/assets/images/services/img-tick.png"
                          :alt="$t('SupportPage.PickNumberBuySim')"
                        /> </span
                      >{{ $t("SupportPage.AccuracyNote2") }} -->
                    </p>
                  </div>
                  <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <!-- <label :class="currentItem.frontImgValid">
                        <img
                          class="pic-user"
                          v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null ||
                          isUploadFront
                          "
                          @click="showCapture('frontImg')"
                          src="@/assets/images/services/img-identity-1.svg"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          @click="showCapture('frontImg')"
                          :src="currentItem.frontImg"
                          alt="Avatar user"
                        />
                      </label> -->
                      
                      <label :for="`${inputRandomKey}front`" style="cursor: pointer;" :class="currentItem.frontImgValid">
                         <input
                           type="file"
                           hidden
                           :name="`${inputRandomKey}front`"
                           :id="`${inputRandomKey}front`"
                           accept="image/png, image/jpeg"
                           @input="(e) => onUploadImage(e, 'frontImg')"
                         />
                         <img
                          class="pic-user"
                          v-if="
                            !currentItem.frontImg
                          "
                          src="@/assets/images/services/img-identity-1.svg"
                          alt="Front img"
                        />
                         <img v-else class="pic-user" alt="img" :src="currentItem.frontImg"/>
                       </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          !currentItem.frontImg
                        "
                      >
                      {{ $t('SupportPage.UploadFrontPhotoWithoutPP') }}<span style="color: red;"> *</span>
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <!-- <label :class="currentItem.backImgValid">
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null ||
                            isUploadBack
                          "
                          @click="showCapture('backImg')"
                          :src="backImgShow"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          @click="showCapture('backImg')"
                          :src="currentItem.backImg"
                          alt="Avatar user"
                        />
                      </label> -->
                      <label :for="`${inputRandomKey}back`" style="cursor: pointer;" :class="currentItem.backImgValid">
                         <input
                           type="file"
                           hidden
                           :name="`${inputRandomKey}back`"
                           :id="`${inputRandomKey}back`"
                           accept="image/png, image/jpeg"
                           @input="(e) => onUploadImage(e, 'backImg')"
                         />
                         <img
                          class="pic-user"
                          v-if="
                            !currentItem.backImg
                          "
                          src="@/assets/images/services/img-identity-2.svg"
                          alt="BackImg img"
                        />
                         <img v-else class="pic-user" alt="img" :src="currentItem.backImg"/>
                       </label>
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.backImg == undefined ||
                          currentItem.backImg == null ||
                          isUploadBack
                        "
                      >
                      {{ $t('SupportPage.UploadBackPhotoWithoutPP') }}<span style="color: red;"> *</span>
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label :for="`${inputRandomKey}face`" style="cursor: pointer;" :class="currentItem.faceImgValid">
                         <input
                           type="file"
                           hidden
                           :name="`${inputRandomKey}face`"
                           :id="`${inputRandomKey}face`"
                           accept="image/png, image/jpeg"
                           @input="(e) => onUploadImage(e, 'faceImg')"
                         />
                         <img
                          class="pic-user"
                          v-if="
                            !currentItem.faceImg
                          "
                          src="@/assets/images/services/img-avatar.svg"
                          alt="faceImg img"
                        />
                         <img v-else class="pic-user" alt="img" :src="currentItem.faceImg"/>
                       </label>
                      <!-- <label :class="currentItem.faceImgValid">
                        <img
                          class="pic-user"
                          v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null ||
                            isUploadFace
                          "
                          @click="showCapture('faceImg')"
                          :src="avtImgShow"
                          alt="Avatar user"
                        />
                        <img
                          class="pic-user"
                          v-else
                          @click="showCapture('faceImg')"
                          :src="currentItem.faceImg"
                          alt="Avatar user"
                        />
                      </label> -->
                      <span
                        class="avatar-txt"
                        v-if="
                          currentItem.faceImg == undefined ||
                          currentItem.faceImg == null ||
                          isUploadFace
                        "
                      >
                      Upload<br> chân dung<span style="color: red;"> *</span>
                      </span>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.FullName") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                              v-if="!isCapture"
                              placeholder="NGUYEN VAN A"
                              class=""
                              :state="errors.length > 0 ? false : null"
                              disabled
                              v-model="currentItem.name"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.IdNumberWithoutPP") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              class="confirm-input disabled"
                              :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                :placeholder="$t('SupportPage.YourIdNumber')"
                                class="icon-card"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.id"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.idNumber ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.idNumber = !confirmData.idNumber"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Dob") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              class="confirm-input disabled"
                              :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                :placeholder="$t('Common.Dob')"
                                class="icon-date"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.birth_day"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.dateOfBirth ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="
                                    confirmData.dateOfBirth = !confirmData.dateOfBirth
                                  "
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.IssueDate") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              class="confirm-input disabled"
                              :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                :placeholder="$t('SupportPage.IssueDate')"
                                disabled
                                class="icon-date"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_date"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.issueDate ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.issueDate = !confirmData.issueDate"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("Common.Address") }}</div>
                      <div class="col p-0">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              class="confirm-input"
                              :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                :placeholder="$t('Common.Address')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.recent_location"
                                @keypress="preventEnterSpecialCharacter($event)"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.address ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="confirmData.address = !confirmData.address"
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                              $t("SupportPage.AddressError")
                            }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("SupportPage.IssuePlace") }}</div>
                      <div class="col p-0">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|specialCharacter"
                        >
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              class="confirm-input"
                              :class="errors.length == 0 ? '' : 'warning-input'"
                            >
                              <b-form-input
                                :placeholder="$t('SupportPage.IssuePlace')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_place"
                                @keypress="preventEnterSpecialCharacter($event)"
                              />
                              <!-- <b-input-group-prepend is-text class="last-input">
                                <img
                                  :class="[
                                    confirmData.issuePlace ? 'confirm' : '',
                                    'confirm-icon',
                                  ]"
                                  src="@/assets/images/img-tick.png"
                                  alt="tick-img"
                                  @click="
                                    confirmData.issuePlace = !confirmData.issuePlace
                                  "
                                />
                              </b-input-group-prepend> -->
                            </b-input-group>
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                              $t("SupportPage.IssuePlaceError")
                            }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="'/' + Slug == $t('Route.SimExchange')"
                    @click="iframeSimExchange()"
                    type="button"
                    class="fs-but next-mask-red"
                    title="Gửi yêu cầu Đổi/cấp lại SIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại SIM
                  </button>
                  <button
                    v-if="'/' + Slug == $t('Route.ESimExchange')"
                    @click="iframeESimExchange()"
                    type="button"
                    class="fs-but next-mask-red"
                    title="Gửi yêu cầu Đổi/cấp lại eSIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại ESIM
                  </button>
                  <!-- <button
                    v-if="'/' + Slug == $t('Route.ESimExchange')"
                    @click="doi_sim(1)"
                    type="button"
                    class="fs-but next-mask-red"
                    title="Gửi yêu cầu Đổi/cấp lại eSIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại eSIM
                  </button>
                  <button
                    v-else
                    @click="doi_sim(0)"
                    type="button"
                    class="fs-but next-mask-red"
                    title="Gửi yêu cầu Đổi/cấp lại SIM"
                  >
                    Gửi yêu cầu Đổi/cấp lại SIM
                  </button> -->
                </div>
              </validation-observer>
            </div>

            <div class="support-block">
              <div class="question-relate">
                <h2>{{ $t("SupportPage.RelatedQuestion") }}</h2>
                <div class="fs-faq-items">
                  <div
                      class="question-relate no-bg"
                      v-for="i in topicData.filter((j) => j.id == 8)"
                      :key="i.Id"
                  >
                    <div class="fs-faq-items">
                      <template
                          v-for="faq of RelatedFaq.filter((j) => j.FaqCategoryId == i.id)"
                      >
                        <div v-if="findStr(faq)" class="fs-faq-item" :key="faq.Id">
                          <p
                              class="h2 mt-2 mb-2"
                              :title="faq.Question"
                              @click="onClickFap(faq)"
                          >
                            {{ faq.Question }}
                          </p>
                          <div class="support-block mb-2" v-if="faq.Id == currentFaq">
                            <div class="faq-content">
                              <div style="text-align: justify;" v-html="faq.Answer"/>
                            </div>
                            <span class="title-like">{{
                                $t("SupportPage.FaqTitleLike")
                              }}</span>
                            <div class="social-like">
                              <ul>
                                <li
                                    class="faq-like"
                                    @click="reactLike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'Liked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-like.png"
                                        :alt="$t('SupportPage.FaqYesText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqYesText") }}</span>
                                </li>
                                <li
                                    class="faq-unline"
                                    @click="reactDislike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'DisLiked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-unlike.png"
                                        :alt="$t('SupportPage.FaqNoText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqNoText") }}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="1==2 && '/' + Slug == $t('Route.SimChangeUser')">
            <div class="support-title">
              <h2>Chuyển chủ quyền</h2>
              <ul class="breadcrumb">
                <li>
                  <router-link
                      :to="$t('Route.Support')"
                      :title="$t('SupportPage.SuportPageHref')"
                  >{{ $t("SupportPage.SuportPageHref") }}
                  </router-link
                  >
                </li>
                <li>
                  <span>Chuyển chủ quyền</span>
                </li>
              </ul>
            </div>

            <div class="support-block">
              <validation-observer ref="modalDKTT">
                <div class="form-box-new">
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.DKTTBCaption") }}</div>
                      <div class="col p-0">
                        <validation-provider
                            #default="{ errors }"
                            rules="required|msisdn"
                        >
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.CCQPhoneRegister')"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                class="icon-phone"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.Phone"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.CCQCaptionContact") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|num">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.CCQContactPhoneRegister')"
                                maxlength="11"
                                pattern="[0-9]{10,11}"
                                class="icon-phone"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.ContactPhone"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.CCQPhoneRegisterError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="fs-group has-line">
                    <div class="col">
                      <div class="fs-caption">
                        {{ $t("SupportPage.CCQImgSimSerialCapption") }}
                      </div>
                      <div class="box-select-image">
                        <div class="fs-box box-card" v-if="!isCapture">
                          <label for="avaSim"
                          >{{ currentItem.simImg }}
                            <img
                                class="pic-user"
                                v-if="
                                currentItem.simImg == undefined ||
                                currentItem.simImg == null
                              "
                                @click="showCapture('simImg')"
                                src="@/assets/images/ho-tro/phoi-sim-1.svg"
                                alt="Avatar user"
                            />
                            <img
                                class="pic-user"
                                v-else
                                @click="showCapture('simImg')"
                                :src="currentItem.simImg"
                                alt="Avatar user"
                            />
                          </label>
                          <span
                              class="avatar-txt"
                              v-if="
                              currentItem.simImg == undefined ||
                              currentItem.simImg == null
                            "
                          >{{ $t("SupportPage.CCQImgSimSerialInput") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>{{ $t("SupportPage.Receiver") }}</h3>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Gender") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <Select2
                                :settings="{ minimumResultsForSearch: -1 }"
                                :placeholder="$t('Common.Choosen gender')"
                                v-model="currentItem.r_gender"
                                :options="gender"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.GenderError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="accuracy-checks fs-group">
                      <div class="fs-caption">{{ $t("Common.Verify") }}</div>
                      <ul class="fs-checkbox">
                        <li>
                          <input
                              id="CMTSimChangeUser"
                              type="radio"
                              v-model="currentItem.r_DocType"
                              class="radio-vetify"
                              name="some-radios2"
                              value="CMT"
                          />
                          <label for="CMTSimChangeUser">{{
                              $t("SupportPage.CMND")
                            }}</label>
                        </li>
                        <li>
                          <input
                              id="CCSimChangeUser"
                              type="radio"
                              v-model="currentItem.r_DocType"
                              class="radio-vetify"
                              name="some-radios2"
                              value="CC"
                          />
                          <label for="CCSimChangeUser">{{ $t("SupportPage.CC") }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accuracy-note">
                    <p>
                      {{ $t("SupportPage.AccuracyNote1") }}
                      <span class="has-small-tick">
                        <img
                            src="@/assets/images/services/img-tick.png"
                            :alt="$t('SupportPage.PickNumberBuySim')"
                        /> </span
                      >{{ $t("SupportPage.AccuracyNote2") }}
                    </p>
                  </div>
                  <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label :class="currentItem.r_frontImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.r_frontImg == undefined ||
                            currentItem.r_frontImg == null
                          "
                            @click="showCapture('r_frontImg')"
                            src="@/assets/images/services/img-identity-1.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('r_frontImg')"
                            :src="currentItem.r_frontImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.r_frontImg == undefined ||
                          currentItem.r_frontImg == null
                        "
                          v-html="$t('SupportPage.TakeFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label :class="currentItem.backImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.r_backImg == undefined ||
                            currentItem.r_backImg == null
                          "
                            @click="showCapture('r_backImg')"
                            src="@/assets/images/services/img-identity-2.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('r_backImg')"
                            :src="currentItem.r_backImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.r_backImg == undefined ||
                          currentItem.r_backImg == null
                        "
                          v-html="$t('SupportPage.TakeBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label :class="currentItem.faceImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.r_faceImg == undefined ||
                            currentItem.r_faceImg == null
                          "
                            @click="showCapture('r_faceImg')"
                            src="@/assets/images/services/img-avatar.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('r_faceImg')"
                            :src="currentItem.r_faceImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.r_faceImg == undefined ||
                          currentItem.r_faceImg == null
                        "
                          v-html="$t('SupportPage.Take user photo')"
                      >
                      </span>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.FullName") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                v-if="!isCapture"
                                placeholder="NGUYEN VAN A"
                                class=""
                                :state="errors.length > 0 ? false : null"
                                disabled
                                v-model="currentItem.r_name"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.IdNumberWithoutPP") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.YourIdNumber')"
                                class="icon-card"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.r_id"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Dob") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Dob')"
                                class="icon-date"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.r_birth_day"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.IssueDate") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssueDate')"
                                disabled
                                class="icon-date"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.r_issue_date"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("Common.Address") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Address')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.r_recent_location"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("SupportPage.IssuePlace") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssuePlace')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.r_issue_place"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.IssuePlaceError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <!--Bên chuyển-->
                  <h3>{{ $t("SupportPage.Sender") }}</h3>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Gender") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required|gender">
                          <b-form-group>
                            <Select2
                                :settings="{ minimumResultsForSearch: -1 }"
                                :placeholder="$t('Common.Choosen gender')"
                                v-model="currentItem.gender"
                                :options="gender"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.GenderError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="accuracy-checks fs-group">
                      <div class="fs-caption">{{ $t("Common.Verify") }}</div>
                      <ul class="fs-checkbox">
                        <li>
                          <input
                              id="CMTSimChangeUser2"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios3"
                              value="CMT"
                          />
                          <label for="CMTSimChangeUser2">{{
                              $t("SupportPage.CMND")
                            }}</label>
                        </li>
                        <li>
                          <input
                              id="CCSimChangeUser2"
                              type="radio"
                              v-model="currentItem.DocType"
                              class="radio-vetify"
                              name="some-radios3"
                              value="CC"
                          />
                          <label for="CCSimChangeUser2">{{ $t("SupportPage.CC") }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accuracy-note">
                    <p>
                      {{ $t("SupportPage.AccuracyNote1") }}
                      <span class="has-small-tick">
                        <img
                            src="@/assets/images/services/img-tick.png"
                            :alt="$t('SupportPage.PickNumberBuySim')"
                        /> </span
                      >{{ $t("SupportPage.AccuracyNote2") }}
                    </p>
                  </div>
                  <div class="box-select-image" v-if="!isCapture">
                    <div class="fs-box box-card">
                      <label :class="currentItem.frontImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.frontImg == undefined ||
                            currentItem.frontImg == null
                          "
                            @click="showCapture('frontImg')"
                            src="@/assets/images/services/img-identity-1.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('frontImg')"
                            :src="currentItem.frontImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.frontImg == undefined ||
                          currentItem.frontImg == null
                        "
                          v-html="$t('SupportPage.TakeFrontPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-card">
                      <label :class="currentItem.backImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.backImg == undefined ||
                            currentItem.backImg == null
                          "
                            @click="showCapture('backImg')"
                            src="@/assets/images/services/img-identity-2.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('backImg')"
                            :src="currentItem.backImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.backImg == undefined || currentItem.backImg == null
                        "
                          v-html="$t('SupportPage.TakeBackPhotoWithoutPP')"
                      >
                      </span>
                    </div>
                    <div class="fs-box box-avatar">
                      <label :class="currentItem.faceImgValid">
                        <img
                            class="pic-user"
                            v-if="
                            currentItem.faceImg == undefined ||
                            currentItem.faceImg == null
                          "
                            @click="showCapture('faceImg')"
                            src="@/assets/images/services/img-avatar.svg"
                            alt="Avatar user"
                        />
                        <img
                            class="pic-user"
                            v-else
                            @click="showCapture('faceImg')"
                            :src="currentItem.faceImg"
                            alt="Avatar user"
                        />
                      </label>
                      <span
                          class="avatar-txt"
                          v-if="
                          currentItem.faceImg == undefined || currentItem.faceImg == null
                        "
                          v-html="$t('SupportPage.Take user photo')"
                      >
                      </span>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.FullName") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                v-if="!isCapture"
                                placeholder="NGUYEN VAN A"
                                class=""
                                :state="errors.length > 0 ? false : null"
                                disabled
                                v-model="currentItem.name"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">
                        {{ $t("SupportPage.IdNumberWithoutPP") }}
                      </div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.YourIdNumber')"
                                class="icon-card"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.id"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("Common.Dob") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Dob')"
                                class="icon-date"
                                disabled
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.birth_day"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="fs-group">
                      <div class="fs-caption">{{ $t("SupportPage.IssueDate") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssueDate')"
                                disabled
                                class="icon-date"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_date"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("Common.Address") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('Common.Address')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.recent_location"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.AddressError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-group flex-spr2">
                    <div class="fs-group w-100">
                      <div class="fs-caption">{{ $t("SupportPage.IssuePlace") }}</div>
                      <div class="col p-0">
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-group>
                            <b-form-input
                                :placeholder="$t('SupportPage.IssuePlace')"
                                class="icon-addr"
                                :state="errors.length > 0 ? false : null"
                                v-model="currentItem.issue_place"
                            />
                            <small class="fs-error-txt" v-if="errors.length > 0">{{
                                $t("SupportPage.IssuePlaceError")
                              }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <!--Bên chuyển-->
                  <button
                      @click="change_user()"
                      type="button"
                      class="fs-but next-mask-red"
                      title="GỬI YÊU CẦU CHUYỂN CHỦ QUYỀN"
                  >
                    GỬI YÊU CẦU CHUYỂN CHỦ QUYỀN
                  </button>
                </div>
              </validation-observer>
            </div>

            <div class="support-block">
              <div class="question-relate">
                <h2>{{ $t("SupportPage.RelatedQuestion") }}</h2>
                <div class="fs-faq-items">
                  <div
                      class="question-relate no-bg"
                      v-for="i in topicData.filter((j) => j.id == 8)"
                      :key="i.Id"
                  >
                    <div class="fs-faq-items">
                      <template
                          v-for="faq of RelatedFaq.filter((j) => j.FaqCategoryId == i.id)"
                      >
                        <div v-if="findStr(faq)" class="fs-faq-item" :key="faq.Id">
                          <p
                              class="h2 mt-2 mb-2"
                              :title="faq.Question"
                              @click="onClickFap(faq)"
                          >
                            {{ faq.Question }}
                          </p>
                          <div class="support-block mb-2" v-if="faq.Id == currentFaq">
                            <div class="faq-content">
                              <div style="text-align: justify;" v-html="faq.Answer"/>
                            </div>
                            <span class="title-like">{{
                                $t("SupportPage.FaqTitleLike")
                              }}</span>
                            <div class="social-like">
                              <ul>
                                <li
                                    class="faq-like"
                                    @click="reactLike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'Liked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-like.png"
                                        :alt="$t('SupportPage.FaqYesText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqYesText") }}</span>
                                </li>
                                <li
                                    class="faq-unline"
                                    @click="reactDislike(faq.Id)"
                                    :class="
                                    faq.currentUserReaction == 'DisLiked' ? 'active' : ''
                                  "
                                >
                                  <div class="fs-pic">
                                    <img
                                        src="@/assets/images/ho-tro/icon-unlike.png"
                                        :alt="$t('SupportPage.FaqNoText')"
                                    />
                                  </div>
                                  <span>{{ $t("SupportPage.FaqNoText") }}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="fs-section fs-question">
        <div class="fs-inr question-inr" v-if="ConfigData.length > 0">
          <div class="fs-txt">
            <h2>{{ $t("Common.NeedHelp") }}</h2>
            <p>{{ $t("Common.SendIdeal") }}</p>
            <div class="fs-buts">
              <a
                  :href="'tel:' + ConfigData.find((i) => i.DataKey == 'PhoneWebsite').Value"
                  class="fs-but"
                  :title="$t('Common.CallItel')"
              >{{ $t("Common.CallItel") }}</a
              >
              <a
                  :href="
                  'mailto:' + ConfigData.find((i) => i.DataKey == 'EmailWebsite').Value
                "
                  class="fs-but"
                  :title="$t('Common.EmailItel')"
              >{{ $t("Common.EmailItel") }}</a
              >
            </div>
          </div>
          <div class="fs-pic">
            <img
                loading="lazy"
                src="@/assets/images/ho-tro/img-any-question.png"
                :alt="$t('ServicePage.CHANGE SIM NUMBER')"
            />
          </div>
        </div>
      </div>
      <b-modal
          body-class="p-0"
          size="lg"
          hide-footer
          id="modal-cam"
          title=""
          @ok="updateStatus(40)"
          hide-header
      >
        <vue-web-cam
            @started="onStarted"
            @camera-change="onCameraChange"
            @cameras="onCameras"
            @stopped="onStopped"
            height="calc(100vw * 1.3)"
            width="100%"
            :selectFirstDevice="true"
            :autoplay="true"
            ref="webcam"
        ></vue-web-cam>
        <button
            class="close1"
            @click="
            isCapture = false;
            $bvModal.hide('modal-cam');
          "
        >
          ×
        </button>
        <button @click="capture()" class="photo1">
          <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30" cy="30" r="28.75" stroke="#ED1F24" stroke-width="2.5"/>
            <circle cx="30" cy="30.0001" r="25.5882" fill="#ED1F24"/>
          </svg>
        </button>
        <button @click="rotate()" class="rotate1">
          <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="#C4C4C4"/>
            <path
                d="M30.5 20.5L27.5 20.5C27.5027 19.0148 27.0642 17.5622 26.2403 16.3265C25.4164 15.0908 24.244 14.1275 22.8719 13.5587C21.4998 12.99 19.9898 12.8415 18.5331 13.132C17.0765 13.4224 15.7389 14.1389 14.69 15.1904L12.575 13.0755C14.0435 11.607 15.9144 10.607 17.9513 10.2018C19.9882 9.79661 22.0996 10.0045 24.0183 10.7993C25.937 11.594 27.5769 12.9398 28.7307 14.6665C29.8844 16.3933 30.5001 18.4233 30.5 20.5V20.5ZM27.425 27.9245C25.9565 29.393 24.0856 30.393 22.0487 30.7982C20.0118 31.2034 17.9004 30.9955 15.9817 30.2007C14.063 29.406 12.4231 28.0602 11.2693 26.3335C10.1156 24.6067 9.49985 22.5767 9.5 20.5L12.5 20.5C12.4973 21.9852 12.9358 23.4377 13.7597 24.6735C14.5836 25.9092 15.7559 26.8725 17.1281 27.4413C18.5002 28.01 20.0102 28.1585 21.4669 27.868C22.9235 27.5776 24.2611 26.8611 25.31 25.8096L27.425 27.9245V27.9245ZM5 20.5L11 14.5004L17 20.5L5 20.5ZM23 20.5L35 20.5L29 26.4996L23 20.5Z"
                fill="black"
            />
          </svg>
        </button>
      </b-modal>
      <PopupLogin :showPopup="showPopupLogin" @onClose="onClosePopupLogin()"></PopupLogin>

      <!--<div class="fb-customerchat" [hidden]="!showMessenger" page_id="<PAGE_ID>">
        </div>-->
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required} from "@validations";
import {Navigation, Pagination} from "swiper";
import {SwiperCore, Swiper, SwiperSlide} from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BForm, VBTooltip,
} from "bootstrap-vue";
import {WebCam} from "vue-web-cam";
import PopupLogin from "./PopupLogin.vue";
import { v4 as uuidv4 } from "uuid";
import ImgPledgeDemo from '../assets/images/picture.png'

//Special character except , -
extend("specialCharacter", (value) => {
  const specialChars = /[a-z0-9A-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲÝỴÝỶỸửữựỳýỵỷỹ\s,-]/;
  return specialChars.test(value);
});

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
      (first4 === "8487" && value.length !== 11) ||
      (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});

extend("min10", (value) => {
  return value.length == 10;
});

extend("min11", (value) => {
  return value.length == 11;
});

extend("gender", (value) => {
  return value > 0
});

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(require.context("@/assets/images/ve-itel/", false, /.(png)$/));

export default {
  components: {
    Swiper,
    SwiperSlide,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    PopupLogin,
    "vue-web-cam": WebCam,
    BFormTextarea
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      deviceId: null,
      deviceIndex: 0,
      isLoading: false,
      isCapture: false,
      currentCapture: "",
      ConfigData: [],
      topicData: [],
      topicSelect: null,
      RelatedFaq: [],
      SearchString: "",
      ListSearchMatchId: [],
      isShowCategoryName: true,
      showPopupLogin: false,
      currentFaq: 0,
      currentItem: {DocType: "CC", r_DocType: "CC"},
      isEmptyTopic: false,
      Slug: "",
      cat: [
        // {Slug: this.$t("Route.SimRegister"), Name: this.$t("Common.DKTTTB")},
        {Slug: this.$t("Route.ESimExchange"), Name: this.$t("Common.ExchangeESim")},
        {Slug: this.$t("Route.SimExchange"), Name: this.$t("Common.ExchangeSim")},
        // {Slug:this.$t('Route.SimChangeUser'),Name:this.$t('Common.ChangUser')},
        {Slug: this.$t("Route.Faq"), Name: this.$t("Common.Faq")},
        {Slug: this.$t("Route.ContactUs"), Name: this.$t("Common.Contact")},
      ],
      gender: [
        {id: 1, text: "Nam"},
        {id: 2, text: "Nữ"},
      ],
      required,
      devices: [],
      confirmData: {
        idNumber: false,
        dateOfBirth: false,
        issueDate: false,
        address: false,
        issuePlace: false,
      },
      isUploadFront: false,
      isUploadBack: false,
      isUploadFace: false,
      imageChecked: {
        img_front: null,
        img_face: null
      },
      isInIframe: false,
      inputPledgeKey: uuidv4(),
      ImgPledgeDemo: ImgPledgeDemo,
      pledgeImgSrc: '',
      cardsOptions: [
        {
          id: 1,
          text: '10.000đ'
        },
        {
          id: 2,
          text: '20.000đ'
        },
        {
          id: 3,
          text: '50.000đ'
        },
        {
          id: 4,
          text: '100.000đ'
        },
        {
          id: 5,
          text: '200.000đ'
        },
        {
          id: 6,
          text: '300.000đ'
        },
        {
          id: 7,
          text: '500.000đ'
        }
      ],
      subscriberTypeOptions: [
        {
          id: 1,
          text: 'Thuê bao chính chủ'
        },
        {
          id: 2,
          text: 'Viết cam kết'
        }
      ],
      // iframeSimExchangeData: {
      //   phoneNumber: '',
      //   subscriberType: null,
      //   pledge: null,
      //   number1: '',
      //   number2: '',
      //   number3: '',
      //   oldSerial: '',
      //   valueRechargeCard: '',
      //   note: '',
      //   docType: 'CC',
      //   frontImg: null,
      //   frontImgValid: false,
      //   backImg: null,
      //   backImgValid: false,
      //   faceImg: null,
      //   faceImgValid: false,
      //   name: '',
      //   id: '',
      //   birth_day: '',
      //   issue_date: '',
      //   recent_location: '',
      //   issue_place: '',
      //   isUploadFront: false,
      //   isUploadBack: false,
      //   isUploadFace: false
      // },
      isCheckValidateIframe: false,
      supportDevice: [ ],
      deviceSelect: '',
      inputSearchDevice: '',
      isShowDeviceSearch: false,
      searchDeviceResult: [],
      selectedDevice:{Code:-1,Name:"Chọn dòng máy"},
      inputRandomKey: uuidv4()
    };
  },
  watch: {
    $route(to, from) {
      this.routeTo(to);
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.isInIframe = (window === window.parent) ? false : true;
    if (this.isInIframe) {
      this.$store.dispatch('app/setIframe', true);
      const headH = document.querySelector('.fs-header');
      if (headH) {
        headH.style.display = 'none';
      }
      const footer = document.querySelector('.fs-footer');
      if (footer) {
        footer.style.display = 'none';
      }
      const chat = document.querySelector('#cs-live-chat');
      if (chat) {
        chat.style.display = 'none';
      }
    }
    window.addEventListener("message", this.handleMessageEventer, false);
    this.routeTo(this.$route);
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
    this.getDevice();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessageEventer);
  },
  methods: {
    onPreviewPledgeImage(){
      const preview = this.$imagePreview({
        initIndex:0,
        images:[this.pledgeImgSrc],
        onClose: () => {}
      })
    },
    onEditPledgeImage(){
      this.$refs.inputPledgeKey.click()
    },
    handleMessageEventer(e) {
      let key = e.message ? "message" : "data";
      let data = e[key];

      if (typeof (data) == 'object') {
        if (data?.type == 'onAddChangeSIMOrderSuccess') {
          this.loading(false);
          this.isLoading = false;

          this.$router.push({
            path: "thong-tin-nhan-sim",
            query: { pn: this.currentItem.Phone, type: "sim", txnRef: data.data },
          });
        } else if (data?.type == 'onAddChangeESIMOrderSuccess') {
          this.loading(false);
          this.isLoading = false;

          this.$router.push({
              path: "thong-tin-nhan-sim",
              query: { pn: this.currentItem.Phone, type: "esim", txnRef: data.data },
          });
        } else if (data?.type == 'onAddChangeSIMOrderFail') {
          this.loading(false);
          this.isLoading = false;
        } else if (data?.type == 'onAddChangeESIMOrderFail') {
          this.loading(false);
          this.isLoading = false;
        }
      }
    },
    getDevice(){
      useJwt.get('device-support-esim').then((res)=>{
        console.log("check res",res.data.result);
       this.supportDevice = res.data.result
        console.log("check device",this.supportDevice);
      }).catch((err)=>{
        console.log("check err",err);
      })
    },
    showDeviceSearch(){
      console.log("check state", );
      this.searchDeviceResult = this.supportDevice
      this.isShowDeviceSearch=!this.isShowDeviceSearch
    },
    searchDevice() {
      this.selectedDevice = {Code:-1,Name:"Chọn dòng máy"};
      this.searchDeviceResult = this.supportDevice.filter((item) => {
        return item.Name.toLowerCase().includes(this.inputSearchDevice.toLowerCase());
      });
      if (this.searchDeviceResult.length === 0) {
        this.searchDeviceResult = [{Code: 0, Name: "Dòng máy của Quý Khách chưa được hỗ trợ"}]
      }
      console.log("check search", this.searchDeviceResult);
    },
    selectDevice(device) {
      if (this.searchDeviceResult.length !== 0 && device.Code !== 0) {
        this.selectedDevice = device;
        this.inputSearchDevice = "";
        this.searchDeviceResult = this.supportDevice
        this.showDeviceSearch()
      }
    },
    hideResultSearch() {
      this.isShowDeviceSearch = false;
    },
    routeTo(to) {
      // if ('/'+to.params.Slug==this.$t('Route.SimExchange') || '/'+to.params.Slug==this.$t('Route.ESimExchange') || '/'+to.params.Slug==this.$t('Route.SimRegister')){
      //   localStorage.redirectUrl = to.path;
      //   if (localStorage.token == undefined || new Date().getTime() - localStorage.tokenTime > 1000*60*100){
      //     // this.$router.push({path:this.$t('Route.Login')});
      //     this.showPopupLogin = true;
      //   }
      // }
      this.clearForm();
      this.slug(to.params.Slug);
      this.fetch();

      const val = localStorage.getItem("formData");
      if (val) {
        this.currentItem = JSON.parse(val);
        localStorage.removeItem("formData");
      }
    },
    onClosePopupLogin() {
      this.showPopupLogin = false;
      this.$router.push({path: `${this.$t("Route.Support")}${this.$t("Route.Faq")}`});
    },
    clearForm() {
      this.currentItem.Phone = "";
      this.currentItem.RecentPhone1 = "";
      this.currentItem.RecentPhone2 = "";
      this.currentItem.RecentPhone3 = "";
      this.currentItem.gender = 0;
      this.currentItem.DocType = "CC";
      this.currentItem.faceImg = null;
      this.currentItem.frontImg = null;
      this.currentItem.backImg = null;
      this.currentItem.backImgValid = false;
      this.currentItem.frontImgValid = false;
      this.currentItem.faceImgValid = false;
      this.currentItem.name = "";
      this.currentItem.id = "";
      this.currentItem.birth_day = "";
      this.currentItem.issue_date = "";
      this.currentItem.recent_location = "";
      this.currentItem.issue_place = "";

      this.currentItem = {...this.currentItem};
      if (this.$refs.modalDKTT) {
        this.$refs.modalDKTT.reset();
      }
    },
    async dktttb() {
      var validate = await this.$refs.modalDKTT.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning("Vui lòng nhập các trường thông tin bắt buộc", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.simImg == undefined || this.currentItem.simImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh SIM, chụp rõ dãy số Seri trên SIM", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (
          this.currentItem.frontImg == undefined ||
          this.currentItem.frontImg.length == 0 ||
          !this.imageChecked.img_front
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.backImg == undefined || this.currentItem.backImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.faceImg == undefined ||
          this.currentItem.faceImg.length == 0 ||
          !this.imageChecked.img_face) {
        this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
          icon: true,
          closeButton: "button",
        });
        return;
      }

      const result = await useJwt.post("ekyc/compare", this.imageChecked);
      if (!result.data || !result.data.result || result.data?.result?.object?.msg == "NOMATCH") {
        this.$toast.warning("Ảnh chân dung không khớp với ảnh trên CMND/CC. Vui lòng kiểm tra lại.", {
          icon: true,
          closeButton: "button",
        });
        this.currentItem["r_frontImg" + "Valid"] = "red-border-class";
        this.currentItem["r_faceImg" + "Valid"] = "red-border-class";
        this.currentItem["frontImg" + "Valid"] = "red-border-class";
        this.currentItem["faceImg" + "Valid"] = "red-border-class";
        return;
      }

      this.currentItem["r_frontImg" + "Valid"] = "";
      this.currentItem["r_faceImg" + "Valid"] = "";
      this.currentItem["frontImg" + "Valid"] = "";
      this.currentItem["faceImg" + "Valid"] = "";

      useJwt.post("masterData/DKTTTB", this.currentItem).then((response) => {
        this.$toast.info(this.$t("PopUp.ConfirmRequest"), {
          icon: true,
          closeButton: "button",
        });
        this.imageChecked.img_front = null;
        this.imageChecked.img_face = null;

        this.$router.push({path: this.$t("Route.Support")});
      });
    },
    async change_user() {
      console.log(this.currentItem);
      var validate = await this.$refs.modalDKTT.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning("Vui lòng nhập các trường thông tin bắt buộc", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.simImg == undefined || this.currentItem.simImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh SIM, chụp rõ dãy số Seri trên SIM", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (
          this.currentItem.frontImg == undefined ||
          this.currentItem.frontImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (
          this.currentItem.r_frontImg == undefined ||
          this.currentItem.r_frontImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.backImg == undefined || this.currentItem.backImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (
          this.currentItem.r_backImg == undefined ||
          this.currentItem.r_backImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.faceImg == undefined || this.currentItem.faceImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (
          this.currentItem.r_faceImg == undefined ||
          this.currentItem.r_faceImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      /*if (this.currentItem.id==this.currentItem.r_id){
        this.$toast.warning("Thông tin BÊN NHẬN và BÊN CHUYỂN giống nhau", {icon:true, closeButton: "button"})
        return;
      }*/
      useJwt.post("masterData/CHANGE_USER", this.currentItem).then((response) => {
        this.$toast.info(this.$t("PopUp.ConfirmRequest"), {
          icon: true,
          closeButton: "button",
        });
        this.$router.push({path: this.$t("Route.Support")});
      });
    },
    async doi_sim(t) {
      this.currentItem.esim == (t == 1);
      let validatePhone = this.checkRecentPhoneMatch();
      // if(!validatePhone){
      //   return
      // }

      // var validate = await this.$refs.modalDKTT.validate().then((success) => {
      //   return success;
      // });
      // if (!validate) {
      //   this.$toast.warning(this.$t("Error.verifyRequire"), {
      //     icon: true,
      //     closeButton: "button",
      //   });
      //   return;
      // }

      this.loading(true);
      this.isLoading = true;

      this.currentItem.RecentPhones = [
        this.currentItem.RecentPhone1,
        this.currentItem.RecentPhone2,
        this.currentItem.RecentPhone3
      ];
      if(this.selectedDevice.Code === -1){
        this.$refs.selectDeviceError.style.display = "block";
        this.$toast.warning("Vui lòng chọn dòng máy", {
          icon: true,
          closeButton: "button",
        });
        this.loading(false);
        this.isLoading=false;
        return;
      }
      useJwt.post('doi-sim/check', {
        Phone: this.currentItem.Phone,
        RecentPhones: this.currentItem.RecentPhones
      }).then(async response =>
      {
        this.loading(false);
        this.isLoading = false;

        if (response?.data?.result?.RecentPhonesCheck < 3) {
          this.$toast.warning(`Bạn đã nhập sai ${new Set(this.currentItem.RecentPhones.filter(el => !!el)).size - response?.data?.result?.RecentPhonesCheck} số điện thoại liên hệ trong 3 tháng gần đây!`);
          return
        }


        if (
            this.currentItem.frontImg == undefined ||
            this.currentItem.frontImg.length == 0
        ) {
          this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (this.currentItem.backImg == undefined || this.currentItem.backImg.length == 0) {
          this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (this.currentItem.faceImg == undefined || this.currentItem.faceImg.length == 0) {
          this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (
            new Set(this.currentItem.RecentPhones.filter(el => !!el)).size < 3
        ) {
          this.$toast.warning(
              "Vui lòng nhập 3 số điện thoại thường xuyên liên lạc không trùng nhau",
              {icon: true, closeButton: "button"}
          );
          return;
        }

        const result = await useJwt.post("ekyc/compare", this.imageChecked);
        if (!result.data || !result.data.result || result.data?.result?.object?.msg == "NOMATCH") {
          this.$toast.warning("Ảnh chân dung không khớp với ảnh trên CMND/CC. Vui lòng kiểm tra lại.", {
            icon: true,
            closeButton: "button",
          });
          this.currentItem["r_frontImg" + "Valid"] = "red-border-class";
          this.currentItem["r_faceImg" + "Valid"] = "red-border-class";
          this.currentItem["frontImg" + "Valid"] = "red-border-class";
          this.currentItem["faceImg" + "Valid"] = "red-border-class";
          return;
        }

        this.currentItem["r_frontImg" + "Valid"] = "";
        this.currentItem["r_faceImg" + "Valid"] = "";
        this.currentItem["frontImg" + "Valid"] = "";
        this.currentItem["faceImg" + "Valid"] = "";


        localStorage.setItem("formData", JSON.stringify(this.currentItem))
        this.currentItem.esim = t;
        this.currentItem.EsimDevice  = this.selectedDevice.Code;
        console.log(this.currentItem);
        useJwt.post('masterData/DOI_SIM', this.currentItem).then(response => {
          this.$toast.info(this.$t("PopUp.ConfirmRequest"), {
            icon: true,
            closeButton: "button",
          });
          this.$router.push({
            path: "thong-tin-nhan-sim",
            query: {pn: this.currentItem.Phone, type: t == 0 ? "sim" : "esim", txnRef: response.data.result},
          });

          // this.$router.push({path:this.$t("Route.Support")});
          // this.$router.push({path: "thong-tin-nhan-sim", phoneNumber: this.currentItem.Phone});
        }).catch(err => {
          this.$toast.error(
              err.response?.data?.message || "Có lỗi trong quá trình đổi sim!",
              {icon: true, closeButton: "button"}
          );
          this.loading(false);
          this.isLoading = false;
        });
      }).catch(err => {
        this.$toast.error(
            err.response?.data?.message || "Có lỗi trong quá trình đổi sim!",
            {icon: true, closeButton: "button"}
        );
        this.loading(false);
        this.isLoading = false;
      });
    },
    showCapture(type) {
      this.currentCapture = type;
      this.isCapture = true;
      this.$bvModal.show("modal-cam");
    },
    showUpload(type) {
      this.currentCapture = type;
    },
    capture(srcImg = null) {
      // if (this.isCapture) {
      //   if (this.currentCapture == "frontImg") {
      //     this.isUploadFront = false;
      //   } else if (this.currentCapture == "backImg") {
      //     this.isUploadBack = false;
      //   } else {
      //     this.isUploadFace = false;
      //   }
      // }

      this.loading(true);
      var img = srcImg ? srcImg : this.$refs.webcam.capture();
      this.currentItem[this.currentCapture] = img;

      if (this.currentCapture == "simImg") {

        useJwt
            .post("ekyc/upload-sim-img", {data: img.substring(23)})
            .then((response) => {
              this.currentItem.simImgUrl = response.data.result.file[0];
              this.loading(false);
              this.isCapture = true;
              this.isCapture = false;
            });
      } else {
        console.log(this.currentCapture);
        useJwt
            .post("ekyc/" + this.currentCapture + "/" + this.currentItem.DocType, {
              data: img.substring(23),
            })
            .then((response) => {
              if (response.data.result.success) {
                this.currentItem[this.currentCapture + "Url"] = response.data.result.fileId;
                this.currentItem[this.currentCapture + "Valid"] = "";
                this.currentItem[this.currentCapture + "Hash"] =
                    response.data.result.object.hash;
                if (this.currentCapture == "frontImg") {
                  this.currentItem.id = response.data.result.object.id;
                  this.currentItem.name = response.data.result.object.name;
                  this.currentItem.birth_day = response.data.result.object.birth_day;
                  this.currentItem.origin_location =
                      response.data.result.object.origin_location;
                  this.currentItem.recent_location =
                      response.data.result.object.recent_location;
                  this.imageChecked.img_front = response.data.result.imgs.img_front;
                } else if (this.currentCapture == "backImg") {
                  this.currentItem.issue_place = response.data.result.object.issue_place;
                  this.currentItem.issue_date = response.data.result.object.issue_date;
                } else if (this.currentCapture == "faceImg") {
                  this.imageChecked.img_face = response.data.result.object.hash;
                }

                if (this.currentCapture == "r_frontImg") {
                  console.log(response.data.result.object);
                  this.currentItem.r_id = response.data.result.object.id;
                  this.currentItem.r_name = response.data.result.object.name;
                  this.currentItem.r_birth_day = response.data.result.object.birth_day;
                  this.currentItem.r_origin_location =
                      response.data.result.object.origin_location;
                  this.currentItem.r_recent_location =
                      response.data.result.object.recent_location;
                  this.imageChecked.img_front = response.data.result.imgs.img_front;
                  console.log(this.currentItem);
                } else if (this.currentCapture == "r_backImg") {
                  this.currentItem.r_issue_place = response.data.result.object.issue_place;
                  this.currentItem.r_issue_date = response.data.result.object.issue_date;
                } else if (this.currentCapture == "r_faceImg") {
                  this.imageChecked.img_front = response.data.result.object.hash;
                }
                this.currentItem = JSON.parse(JSON.stringify(this.currentItem));
                this.loading(false);
                this.isCapture = false;
              } else {
                this.currentItem[this.currentCapture + "Valid"] = "red-border-class";
                if (this.currentCapture == "frontImg" || this.currentCapture == "r_frontImg") {
                  this.imageChecked.img_front = null;
                } else if (this.currentCapture == "faceImg" || this.currentCapture == "r_faceImg") {
                  this.imageChecked.img_face = null;
                }
                this.loading(false);
                this.$toast.error("Sai loại giấy tờ. Vui lòng kiểm tra lại!", {
                  icon: true,
                  closeButton: "button",
                });
                this.isCapture = false;
              }
            })
            .catch((err) => {
              this.loading(false);
              this.isCapture = false;
              this.$toast.error("Có lỗi trong khi xử lý KYC, Quý khách vui lòng thử lại!", {
                icon: true,
                closeButton: "button",
              });

              if (this.currentCapture == "frontImg" || this.currentCapture == "r_frontImg") {
                this.imageChecked.img_front = null;
              } else if (this.currentCapture == "faceImg" || this.currentCapture == "r_faceImg") {
                this.imageChecked.img_face = null;
              }
            });
      }
      this.$bvModal.hide("modal-cam");
    },
    findStr(i) {
      // if (i.Question.toLowerCase().indexOf(this.SearchString.toLowerCase())>=0){
      //   return true;
      // }
      // return false;
      if (this.ListSearchMatchId.indexOf(i.Id) > -1) {
        return true;
      }
      return false;
    },
    slug(s_) {
      this.isLoading = true;
      this.Slug = s_;
      if (this.Slug == undefined) {
        this.Slug = this.cat[4].Slug.substring(1);
      }
      this.cat.forEach((i) => (i.class = ""));
      if (this.cat.find((i) => i.Slug == "/" + this.Slug) != undefined) {
        this.cat.find((i) => i.Slug == "/" + this.Slug).class = "active";
      }
      this.isLoading = false;
    },
    Img(pic) {
      return images[pic];
    },
    reactLike(id) {
      this.isLoading = true;
      useJwt
          .put("masterData/FaqLike", {Id: id})
          .then((response) => {
            console.log(response.data.result);
            this.RelatedFaq.find((i) => i.Id == id).currentUserReaction = "Liked";
            //this.$toast({component: ToastificationContent,props: {title: 'Cảm ơn quý khách!',icon: 'CheckSquareIcon',variant: 'primary'}})
            this.$toast.info("Thành công, cảm ơn bạn đã đóng góp ý kiến!", {
              icon: true,
              closeButton: "button",
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    reactDislike(id) {
      this.isLoading = true;
      useJwt
          .put("masterData/FaqDisLike", {Id: id})
          .then((response) => {
            console.log(response.data.result);
            this.RelatedFaq.find((i) => i.Id == id).currentUserReaction = "DisLiked";
            //this.$toast({component: ToastificationContent,props: {title: 'Cảm ơn quý khách!',icon: 'CheckSquareIcon',variant: 'primary'}})
            this.$toast.info("Thành công, cảm ơn bạn đã đóng góp ý kiến!", {
              icon: true,
              closeButton: "button",
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    async fetch() {
      this.loading(true);
      await useJwt
          .post("getMasterData/FaqCategory", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.topicData = response.data.result;
            this.topicData.splice(0, 0, {id: "0", text: "Tất cả"});
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu danh mục câu hỏi, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/ConfigData", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.ConfigData = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu cấu hình, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/RelatedFaq", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.RelatedFaq = response.data.result;
            this.ListSearchMatchId = this.RelatedFaq.map((el) => el.Id);
            if ("/" + this.Slug == this.$t("Route.Faq")) {
              this.onSearch(true);
            }
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu danh mục câu hỏi liên quan, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      this.loading(false);
    },
    playVideo() {
      const video = document.getElementsByClassName("video-main news-item");
      video[0].children[3].children[0].setAttribute(
          "src",
          "https://www.youtube.com/embed/" +
          this.ListVideoBlog[this.currentVideo].VideoUrl +
          "?rel=0&amp;autoplay=1&amp;playsinline=1"
      );
      if (!video[0].classList.contains("show-video")) {
        video[0].classList.add("show-video");
      }
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
          "https://www.youtube.com/embed/" +
          url +
          "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    rotate() {
      console.log(this.deviceId);
      this.deviceIndex++;
      if (this.deviceIndex > this.devices.length - 1) {
        this.deviceIndex = 0;
      }
      var c = this.devices.find(
          (i) => i.deviceId != this.deviceId && this.devices.indexOf(i) >= this.deviceIndex
      );
      if (c != undefined) {
        this.$refs.webcam.changeCamera(c.deviceId);
      }
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
    onStarted(stream) {
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async uploadImage(e, type) {
      this.loading(true);
      this.isCapture = false;
      if (!e.target.files) {
        this.$toast.error("Chưa chọn ảnh!", {icon: true, closeButton: "button"});
        this.loading(false);
        return;
      }
      const file = await this.toBase64(e.target.files[0]);
      this.capture(file);
      e.target.value = null;

      if (type == 1) {
        this.isUploadFront = true;
      } else if (type == 2) {
        this.isUploadBack = true;
      } else {
        this.isUploadFace = true;
      }
    },
    onStopped() {
      this.isCapture = false;
    },
    onSearch(isRouteChange = false) {
      if (!this.SearchString.trim()) {
        if (!isRouteChange) {
          this.$toast.warning("Bạn vui lòng nhập vào câu hỏi");
        }
        this.RelatedFaq.forEach((el) => {
          this.ListSearchMatchId.push(el.Id);
        });
        this.checkEmptyTopic();
        this.isShowCategoryName = true;
        return;
      }
      if (!(this.Slug == undefined || "/" + this.Slug == this.$t("Route.Faq"))) {
        this.$router.push({path: `${this.$t("Route.Support")}${this.$t("Route.Faq")}`});
      }

      this.ListSearchMatchId = [];
      this.RelatedFaq.forEach((el) => {
        if (el.Question.toLowerCase().indexOf(this.SearchString.toLowerCase()) >= 0) {
          this.ListSearchMatchId.push(el.Id);
        }
      });
      this.isShowCategoryName = false;
      this.checkEmptyTopic();
    },
    onSelectTopic() {
      this.checkEmptyTopic();
    },
    checkEmptyTopic() {
      let isFindTopic = false;
      if (this.topicData?.length > 0) {
        this.topicData.forEach((j) => {
          if (!this.topicSelect || this.topicSelect == '0' || j.id > 0 && (j.id == this.topicSelect)) {
            this.RelatedFaq.filter((i) => i.FaqCategoryId == j.id).forEach((faq) => {
              if (this.findStr(faq)) {
                if (!isFindTopic) {
                  isFindTopic = true;
                }
              }
            });
          }
        });
      }
      if (!isFindTopic) {
        this.isEmptyTopic = true;
      } else {
        this.isEmptyTopic = false;
      }
    },
    validateRecentphone(value) {
      if (!value || !value.trim()) {
        return true;
      }
      let recentPhones = [
        this.currentItem.RecentPhone1,
        this.currentItem.RecentPhone2,
        this.currentItem.RecentPhone3
      ];
      if (recentPhones.filter((el) => el == value).length >= 2) {
        return false;
      }
      return true;
    },
    checkRecentPhoneMatch() {
      let validate = true;
      let recentPhones = [
        this.currentItem.RecentPhone1,
        this.currentItem.RecentPhone2,
        this.currentItem.RecentPhone3
      ];
      recentPhones.forEach((el) => {
        if (!this.validateRecentphone(el)) {
          if (validate) {
            validate = false;
          }
        }
      });
      if (!validate) {
        this.$toast.warning("SĐT bị trùng, vui lòng kiểm tra lại");
      }
      return validate
    },
    preventEnterSpecialCharacter(event, isPhone = false) {
      if (isPhone) {
        let rexgexPhone = /[0-9]/;
        var key1 = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (rexgexPhone.test(key1)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
      let regex = /[a-z0-9A-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲÝỴÝỶỸửữựỳýỵỷỹ\s,-]/;
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    onClickFap(faq) {
      if (!this.currentFaq || this.currentFaq != faq.Id) {
        this.currentFaq = faq.Id;
      } else {
        this.currentFaq = 0;
      }
    },
    isNumber(evt, val) {
      const keyPressed = evt.key;

      if (val.length >= 10 && keyPressed !== 'Delete' && keyPressed !== 'Backspace' && keyPressed !== 'ArrowLeft' && keyPressed !== 'ArrowRight' && keyPressed !== 'ArrowUp' && keyPressed !== 'ArrowDown') {
        evt.preventDefault()
        return;
      }
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    async onLoadPledge(e) {
      if (e?.target?.files[0]) {
        const obj = e?.target?.files[0]

        this.loading(true);
        
        let imgBase64= await this.toBase64(obj)
        useJwt
            .post("ekyc/upload-sim-img", {data: imgBase64.substring(23)})
            .then((response) => {
              this.currentItem.CommitmentImage = response.data.result.file[0];
              this.pledgeImgSrc = URL.createObjectURL(obj)

              this.loading(false);
            });
      }
      this.inputPledgeKey = uuidv4();
    },
    async iframeSimExchange() {

      this.isCheckValidateIframe = true

      const isCheck = await this.isPassOptionalCondition()

      const validate = await this.$refs.modalDKTTIframe.validate().then((success) => {
        return success;
      });

      this.currentItem.RecentPhones = [
        this.currentItem.RecentPhone1,
        this.currentItem.RecentPhone2,
        this.currentItem.RecentPhone3
      ];

      if(!isCheck || !validate) {
        this.$toast.warning(`Vui lòng kiểm tra lại dữ liệu!`);
        return
      }

      if (
        this.currentItem.frontImg == undefined ||
        this.currentItem.frontImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.backImg == undefined || this.currentItem.backImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.faceImg == undefined || this.currentItem.faceImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
          icon: true,
          closeButton: "button",
        });
        return;
      }


      const result = await useJwt.post("ekyc/compare", this.imageChecked);
      if (!result.data || !result.data.result || result.data?.result?.object?.msg == "NOMATCH") {
        this.$toast.warning("Ảnh chân dung không khớp với ảnh trên CMND/CC. Vui lòng kiểm tra lại.", {
          icon: true,
          closeButton: "button",
        });
        this.currentItem["r_frontImg" + "Valid"] = "red-border-class";
        this.currentItem["r_faceImg" + "Valid"] = "red-border-class";
        this.currentItem["frontImg" + "Valid"] = "red-border-class";
        this.currentItem["faceImg" + "Valid"] = "red-border-class";
        return;
      }

      this.currentItem["r_frontImg" + "Valid"] = "";
      this.currentItem["r_faceImg" + "Valid"] = "";
      this.currentItem["frontImg" + "Valid"] = "";
      this.currentItem["faceImg" + "Valid"] = "";

      this.currentItem.esim = false;
      localStorage.setItem("formData", JSON.stringify(this.currentItem))

      this.loading(true)
      parent.postMessage({type: 'onAddChangeSIMOrder', data: this.currentItem}, '*')
    },
    async iframeESimExchange() {
      this.isCheckValidateIframe = true

      const isCheck = await this.isPassOptionalCondition()

      const validate = await this.$refs.modalDKTTIframe.validate().then((success) => {
        return success;
      });

      this.currentItem.RecentPhones = [
        this.currentItem.RecentPhone1,
        this.currentItem.RecentPhone2,
        this.currentItem.RecentPhone3
      ];

      if(!isCheck || !validate) {
        this.$toast.warning(`Vui lòng kiểm tra lại dữ liệu!`);
        return
      }

      if (
        this.currentItem.frontImg == undefined ||
        this.currentItem.frontImg.length == 0
      ) {
        this.$toast.warning("Vui lòng chụp ảnh mặt trước CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.backImg == undefined || this.currentItem.backImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh mặt sau CMND/CCCD", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.currentItem.faceImg == undefined || this.currentItem.faceImg.length == 0) {
        this.$toast.warning("Vui lòng chụp ảnh khuôn mặt", {
          icon: true,
          closeButton: "button",
        });
        return;
      }


      const result = await useJwt.post("ekyc/compare", this.imageChecked);
      if (!result.data || !result.data.result || result.data?.result?.object?.msg == "NOMATCH") {
        this.$toast.warning("Ảnh chân dung không khớp với ảnh trên CMND/CC. Vui lòng kiểm tra lại.", {
          icon: true,
          closeButton: "button",
        });
        this.currentItem["r_frontImg" + "Valid"] = "red-border-class";
        this.currentItem["r_faceImg" + "Valid"] = "red-border-class";
        this.currentItem["frontImg" + "Valid"] = "red-border-class";
        this.currentItem["faceImg" + "Valid"] = "red-border-class";
        return;
      }

      this.currentItem["r_frontImg" + "Valid"] = "";
      this.currentItem["r_faceImg" + "Valid"] = "";
      this.currentItem["frontImg" + "Valid"] = "";
      this.currentItem["faceImg" + "Valid"] = "";

      this.currentItem.esim = true;
      this.currentItem.EsimDevice  = this.selectedDevice.Code;
      localStorage.setItem("formData", JSON.stringify(this.currentItem))

      this.loading(true);
      parent.postMessage({type: 'onAddChangeESIMOrder', data: this.currentItem}, '*')
    },
    async isPassOptionalCondition() {
      if(
        !this.currentItem.RecentPhone1 &&
        !this.currentItem.RecentPhone2 &&
        !this.currentItem.RecentPhone3 &&
        !this.currentItem.RecentSimSerial &&
        !this.currentItem.LastRecharge)
        return false


      const dataCheck = {
        Phone: this.currentItem.Phone,
        RecentPhones: [
          this.currentItem.RecentPhone1,
          this.currentItem.RecentPhone2,
          this.currentItem.RecentPhone3
        ],
        RecentSimSerial: this.currentItem.RecentSimSerial,
        LastRecharge: this.currentItem.LastRecharge
      }

      const rs = await useJwt.post('doi-sim/check',dataCheck)

      if(rs.data && rs.data.code == 200) {

        // TODO: check if want invalid
        // if(!rs.data.result.LastRechargeCheck && !rs.data.result.SimSerialCheck && rs.data.result.RecentPhonesCheck < 3){
        //   return false
        // }

        return (!!rs.data.result.LastRechargeCheck)
        || (!!rs.data.result.RecentPhonesCheck)
        || (!!rs.data.result.SimSerialCheck)
      }

      return false
    },
    async onUploadImage(e, currentCapture) {
      this.inputRandomKey = uuidv4()
      if (e?.target?.files[0]) {
        const obj = e?.target?.files[0]

        this.loading(true);
        
        let imgBase64 = await this.toBase64(obj)

        this.currentItem[currentCapture] = imgBase64;
        useJwt
            .post("ekyc/" + currentCapture + "/" + this.currentItem.DocType, {
              data: imgBase64.substring(23),
            })
            .then((response) => {
              if (response.data.result.success) {
                this.currentItem[currentCapture + "Url"] = response.data.result.fileId;
                this.currentItem[currentCapture + "Valid"] = "";
                this.currentItem[currentCapture + "Hash"] =
                    response.data.result.object.hash;
                if (currentCapture == "frontImg") {
                  this.currentItem.id = response.data.result.object.id;
                  this.currentItem.name = response.data.result.object.name;
                  this.currentItem.birth_day = response.data.result.object.birth_day;
                  this.currentItem.origin_location =
                      response.data.result.object.origin_location;
                  this.currentItem.recent_location =
                      response.data.result.object.recent_location;
                  this.imageChecked.img_front = response.data.result.imgs.img_front;
                } else if (currentCapture == "backImg") {
                  this.currentItem.issue_place = response.data.result.object.issue_place;
                  this.currentItem.issue_date = response.data.result.object.issue_date;
                } else if (currentCapture == "faceImg") {
                  this.imageChecked.img_face = response.data.result.object.hash;
                }

                if (currentCapture == "r_frontImg") {
                  this.currentItem.r_id = response.data.result.object.id;
                  this.currentItem.r_name = response.data.result.object.name;
                  this.currentItem.r_birth_day = response.data.result.object.birth_day;
                  this.currentItem.r_origin_location =
                      response.data.result.object.origin_location;
                  this.currentItem.r_recent_location =
                      response.data.result.object.recent_location;
                  this.imageChecked.img_front = response.data.result.imgs.img_front;
                } else if (currentCapture == "r_backImg") {
                  this.currentItem.r_issue_place = response.data.result.object.issue_place;
                  this.currentItem.r_issue_date = response.data.result.object.issue_date;
                } else if (currentCapture == "r_faceImg") {
                  this.imageChecked.img_front = response.data.result.object.hash;
                }
                this.currentItem = JSON.parse(JSON.stringify(this.currentItem));
                this.loading(false);
                this.isCapture = false;
              } else {
                this.currentItem[currentCapture + "Valid"] = "red-border-class";
                if (currentCapture == "frontImg" || currentCapture == "r_frontImg") {
                  this.imageChecked.img_front = null;
                } else if (currentCapture == "faceImg" || currentCapture == "r_faceImg") {
                  this.imageChecked.img_face = null;
                }
                this.loading(false);
                this.$toast.error("Sai loại giấy tờ. Vui lòng kiểm tra lại!", {
                  icon: true,
                  closeButton: "button",
                });
                this.isCapture = false;
              }
            })
            .catch((err) => {
              this.loading(false);
              this.isCapture = false;
              this.$toast.error("Có lỗi trong khi xử lý KYC, Quý khách vui lòng thử lại!", {
                icon: true,
                closeButton: "button",
              });

              if (currentCapture == "frontImg" || currentCapture == "r_frontImg") {
                this.imageChecked.img_front = null;
              } else if (currentCapture == "faceImg" || currentCapture == "r_faceImg") {
                this.imageChecked.img_face = null;
              }
            });
      }
    }
  },
};
</script>
<style lang="scss">
.btn-preview-image-container{
  position: absolute;
  width: 100px;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.4);
  display: none;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 8px;
  img{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.label-pledge-container:hover .btn-preview-image-container{
  display: flex;
}
.row-gap-2 {
  row-gap: 20px;
}

.select2-results__option {
  font-size: 1.8rem;
  font-weight: 400 !important;
}

.select2-results__option[aria-selected=true] {
  background-color: aqua !important;
}

@media screen and (max-width: 1100px) {
  .select2-results__option {
    font-size: 3.8647vw;
    font-weight: 400 !important;
  }
}
</style>
<style scoped>
.ul-device-search {
  width: 88%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: inline-block;
  position: absolute;
  top: 100%;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #e0e0e0;
}
.ul-device-search-result {
  max-height: 250px;
  overflow-y: auto;
}
.selectedDevice{
  border: 1px solid rgba(119, 119, 119, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  padding: 0.1rem 12% 0.1rem 1.5rem;
  height: 5rem;
  font-weight: 400;
  font-size: 1.8rem;
  border-radius: 1rem;
}
.search-device-result {
  font-size: 16px;
  padding: 1rem;
}

.search-device-result:hover {
  background-color: #fae2e2;
}

.contact-icon {
  width: 36px;
  height: 36px;
  background: transparent;
}

.warning-input {
  border-color: red !important;
}

.group-question {
  display: inline-flex;
  width: 100%;
}

.btn-question-search {
  width: 60px;
  background: #ffffff;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.btn-question-search img {
  widows: 24px;
  height: 24px;
}
</style>
<style>
#modal-cam___BV_modal_backdrop_ {
  background-color: #333;
  opacity: 1;
}

.close1 {
  position: absolute;
  right: 15px;
  top: 6px;
  font-weight: 400;
  font-size: 5rem;
  color: #333;
}

video {
  border-radius: 0.358rem;
}

.photo1 {
  position: absolute;
  right: 40%;
  left: 40%;
  bottom: 10px;
}

.rotate1 {
  position: absolute;
  right: 25px;
  bottom: 20px;
}

.modal-dialog {
  margin-top: 10vh;
}

.confirm-input {
  display: flex;
  align-items: center;
  border: 1px solid rgba(119, 119, 119, 0.2);
  box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  /* background: #efefef; */
}

.confirm-input > input {
  border: none !important;
  box-shadow: none !important;
}

.confirm-input .last-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-input .confirm-icon {
  display: inline-block;
  vertical-align: middle;
  width: 55%;
  max-width: 55%;
  height: auto;
  filter: grayscale(1);
  cursor: pointer;
}

.confirm-input.disabled {
  background: #efefef;
}

.confirm.confirm-icon {
  filter: grayscale(0);
}
</style>
